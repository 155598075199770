body {
  background: #e3e3e3;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: black;
  text-align: center;
  height: 100%;

  -webkit-transition: background-color 0.5s linear;

  transition: background-color 0.5s linear;
}

.question .authors {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  margin: 40px auto;
}

.author {
  background: rgb(238, 241, 242);
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 2rem;
  margin-left: 1rem;
  width: 30%;
  max-height: 70%;
  cursor: pointer;
}

.author:hover {
  background: rgb(179, 180, 180);
  border-radius: 30px;
  overflow: hidden;
}

img {
  width: 100%;
}

.loader {
  width: 20px;
  display: none;
}
